.select_file {
  text-align: left;
  background-color: lightgray;
  padding: 25px;
  border-radius: 5px;
  width: 500px;
  margin: 10px auto;
}
.select_file .hidden {
  display: none;
}
.select_file input {
  margin-bottom: 10px;
}
.select_file label {
  font-weight: bold;
}

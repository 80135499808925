.post_contents {
  text-align: left;
  background-color: lightgray;
  border-radius: 5px;
  padding: 25px;
  width: 500px;
  margin: 5px auto;
}
.post_contents button {
  margin-right: 10px;
  width: 100px;
}
.post_contents label {
  font-weight: bold;
}
#content_area {
  margin-bottom: 5px;
  height: 250px;
}
